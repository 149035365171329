.modal-container {
  background: rgba(black, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  margin: auto;
  top: 0;
  z-index: 999;

  .modal {
    width: fit-content;
    height: fit-content;
    //display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #1f2d5e;
    //padding: 10px 20px 10px;
    gap: 1rem;
    border-radius: 5px;

    h1 {
      font-size: smaller;
      letter-spacing: 1.25px;
      font-weight: bold;
      user-select: none;
      cursor: default;
      width: 100%;
      margin: 0 !important;
      padding: 1em 0 !important;
    }

    .close {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      height: 2rem;
      width: 2rem;
      z-index: 999;
      cursor: pointer;
    }

    .modal-buttons {
      width: available !important;
      margin-left: auto;
      margin-right: auto;

      :not(:last-child) {
        margin-right: 2em;
      }
    }

    p {
      font-size: small;
      white-space: pre-wrap;
      text-align: center;
      line-height: 1.5rem;
      user-select: none;
      cursor: default;
    }
  }
}
