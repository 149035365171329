@import 'src/styles/shared';

.statement-modal {
  display: flex;
  flex-flow: column;
  min-width: 60vw;
  //height: 100%;
  z-index: 999;

  .tips {
    font-weight: bold;
    margin-top: 1em;
    user-select: none !important;
    cursor: default;
  }

  .progress-bar {
   height: 5px;
   background: #00eefe;

    -webkit-transition: width 1s linear;
    -moz-transition: width 1s linear;
    -o-transition: width 1s linear;
    transition: width 1s linear;
  }

  .statement-modal-title {
    position: relative;
    color: #d15969;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    gap: 2em;
    letter-spacing: 0.5px;
    line-height: 100%;
    vertical-align: center;
    user-select: none !important;
    -webkit-user-select: none;
    cursor: default;

    span {
      display: block;
      margin: 1em 3em;
      color: #d15969;
    }

    img {
      position: absolute;
      width: 1.5em;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 1em;
      object-fit: contain;
      cursor: pointer;

      &:hover {
        background: white;
        border-radius: 50%;
      }
    }
  }

  .video-container {
    background: black;
    position: relative;
    width: 100%;

    .face-centering-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999 !important;
      object-fit: contain;
      max-height: 70vh;
    }

    video {
      width: 100%;
      max-height: 50vh;
      height: 50vh;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

input[type=checkbox] {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}

h1 {
  height: fit-content;
  display: inline-block;
  min-width: 33% !important;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em !important;
  font-weight: 400 !important;
  padding-bottom: 1em;
  border-bottom: solid 3px #67c6df;
}

label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}
select {
  height: fit-content;
  width: fit-content;
  min-width: 20vw;
  margin-left: auto;
  margin-right: auto;
  background: unset;
  color: lightgrey;

}

.modal {
  max-height: 80% !important;
  overflow: auto;
}

.statement-survey-modal {
  display: flex;
  flex-flow: column;
  gap: 2em;
  z-index:999;
  border-radius: 3px;
  background: white;
  position: relative;
  color: white;
  overflow-y: auto;

  .restart-hint {
    margin-top: 1em;
    font-size: smaller;
  }

  p, select, textarea, label, span {
    color: black;
    font-size: 1em !important;
  }

  textarea {
    color: black;
  }

  h1 {
    color: #67c6df;
    width: 100%;
    border-radius: 3px;
    background: #1f2d5e;
    top: 0;
    padding-top: 1em;
    text-align: center;
    margin: auto;
  }

  .statement-survey-modal-form {
    display: flex;
    flex-flow: column;
    max-width: 40vw;
    margin: 0 1em;
    @media screen and (max-width: 900px) {
      max-width: 80vw;
    }

    .upload-monitor {
      font-weight: bold;
      background: rgba(lightgrey, 0.25);
      border: solid 1px rgba(#1f2d5e, 0.2);
      border-radius: 10px;
      ;
    }

    .upload-finished {
      background: rgba(greenyellow, 0.25);
    }

    .label-symptoms {
      margin-top: 1em;
    }
  }

  .statement-survey-modal-content {
  }

  .statement-survey-modal-buttons {
    display: flex;
    margin: 0 1em;
    flex-flow: column;
    align-items: center;
    padding-bottom: 1em;
    gap: 0.5em;
  }
}