.patient-create {
  width: 100%;

  .back-btn-list {
    margin: 1em;
  }

  .header {
    justify-content: unset !important;
  }

  table {

    input {
      color: black;
    }

    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
    @media screen and (max-width: 500px) {
      width: 95%;
      padding-left: 2.5%;
      padding-right: 0;
    }
  }
}