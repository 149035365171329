@import '../../styles/shared';

.b2b-admin-dashboard {
  margin-top: 100px;
  width: 536px;
  display: flex;
  flex-flow: column;

  & > * {
    width: 100%;
    margin: 15px 0;
  }
}