.button {
  color: white;
  background: #1f2d5e;
  padding: 0.5rem 1rem;
  border: solid 1px #0d77e6;
  border-radius: 30px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 600;

  &.align-left {
    margin-right: auto;
  }

  &.align-right {
    margin-left: auto;
  }

  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    border: solid 1px #1f2d5e;
  }

  &.secondary {
    color: white;
    background: unset;

    &:hover {
      color: white;
      background: #1f2d5e;
    }
  }

  &.light-blue {
    background: #0d77e6;
  }

  &.clear {
    background: unset;
    border-color: #1f2d5e;
    color: #1f2d5e;

    &:hover {
      background: lightgrey;
    }
  }

  &.red {
    color: red;
    border-color: red;
    background: white;

    &:hover {
      color: white;
      background: red;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.link {
    border: unset !important;
    background: transparent !important;
    text-align: center;
    color: black;
    margin: unset !important;
    padding: unset !important;
    font-weight: bold;
    text-transform: unset;
    letter-spacing: unset;
    font-size: small !important;
    text-decoration: underline;
  }
}