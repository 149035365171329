@import 'src/styles/shared';

.tabbed-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  align-content: center;
}