@import 'src/styles/shared';

.file-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F7F9;
  border: none;
  width: 100%;
  min-height: px-to-rem(40px);
  //margin: 0;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  //padding: 0 0 0 1rem;
  margin-top: 0.25rem;

  .file-input-row {
    padding: .5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.add:hover {
      cursor: pointer;
    }
  }

  & * {
    font-size: 20px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    margin-block: 0;
    display: inline-block;
  }

  .accessory-icon {
    margin: px-to-rem(-6px); // doesn't expand input field with its size
    height: px-to-rem(40px);
    width: auto;
    object-fit: contain;

    &:hover {
      cursor: pointer;
    }
  }

  .placeholder {
    color: grey;
    font-style: italic;
  }

  .file-name {
    //max-width: 50%;
    color: black;
    font-style: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    overflow-wrap: anywhere;

    &.unsaved {
      color: green;
      font-weight: 600;
    }
  }
}

.file-input-image-preview-container {
  //width: 100%;

  &:not(:empty) {
    margin-top: 0.5rem;
  }

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  .file-input-image-preview {
    max-width: 50%;
    flex: 1 0 26%; // only 3 per rows
    margin: 0.5rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      //object-fit: scale-down;
    }
  }
}

.remove-icon {
  width: 29px;
  height: 29px;
  object-fit: contain;

  &.corner {
    transform: translate(50%, -50%);
    position: absolute;
    top: 0;
    right: 0;
  }
}