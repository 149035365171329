@import 'src/styles/shared';

.report-details {
  display: flex;
  flex-flow: column;
  gap: 1em;
  font-size: small;
  text-align: center;

  max-width: 500px;

  table {
    max-width: 500px !important;

    td {
      min-width: 100px;
    }

    * {
      word-break: break-word;
    }
  }
}