.patient-profile-as-doctor {
  display: flex;
  flex-flow: column;
  padding: 1em !important;

  .column-header {
    margin-top: 2em;

    @media screen and (max-width: 500px) {
      margin-top: 0;
    }
  }

  .patient-full-name {
    background: rgba(#00D0FF, 0.1);
    padding: 1rem;
    margin: 0 -0.5rem;
    border: solid 1px white;
    border-radius: 3px;
  }

  table {
    margin-bottom: 3em;
  }
}