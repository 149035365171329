.patient-edit {
  width: 100%;

  .header {
    justify-content: unset !important;
  }

  table {

    input {
      color: black;
    }

    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
  }
}