@import 'src/styles/shared';

* {
  color: white;
}

.root-container {
  margin: auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .logo {
    height: 33vh;
    max-width: 90vw;
    object-fit: contain;
  }

  .btn-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 3em;
  }
}