.patient-profile {
  display: flex;
  flex-flow: column;
  padding: 1em !important;

  .patient-profile-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    background: #1f2d5e linear-gradient(90deg, rgba(#00eefe, 0.25), rgba(#00eefe, 0.00)) !important;
    border-radius: 1.5em;
    letter-spacing: 1.5px;
    height: 3em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin: 0 -0.5rem;
    border: solid 1px white;

    p {
      color: white;
    }
  }

  .declare-chgt-btn {
    padding: 1.5em;
  }

  .patient-profile-value {
    font-weight: bold;
  }

  .modify-password-btn {
    font-size: unset !important;
  }
}