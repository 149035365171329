.report-list {
  .open-report-details {
    font-size: small;
    //color: #0d77e6;
    font-weight: bold;
    text-decoration: underline;
  }

  .report-adapter-item {
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
    cursor: pointer;
    user-select: none;

    img {
      height: 24px !important;
      padding-right: 0.25em;
    }
  }

  .PENDING {
    color: #1f2d5e;
  }

  .STARTED {

  }

  .STALLED {
    color: #cc5858;
  }

  .TO_ANALYZE {
  }

  .PROCESSING {

  }

  .ALGO_STALLED {

  }

  .TO_REVIEW {
    color: #0d77e6;
  }

  .OK {

  }
}