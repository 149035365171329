.password-change-form {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  gap: 0.5em;
  padding-bottom: 1em;

  .field-error {
    color: #cc5858;
    padding: 0;
    margin: 0 0 1.5em;
  }

  .password-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5em;
    border-radius: 5px;
    border: solid 1px grey;

    .password-eye {
      height: 100%;
      width: 1.5em;
      margin-right: 0.5em;
    }

    input {
      border: none;
    }
  }
}