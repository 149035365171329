@import 'src/styles/shared';

input {
  background: unset;
  padding: 1rem 0.5rem;

  color: black;
  font-size: small;
  border-radius: 5px;
  border: solid 1px grey;
}

.label-error {
  color: palevioletred;
  cursor: default;
  font-size: small;
  user-select: none;
  text-align: center;
}

.login-forgot-password {
  cursor: pointer;
  color: grey;
  text-decoration: underline;
  font-size: small;
  margin: 0;

  &:hover {
    color: #0d77e6;
  }
}
.login-padding-box {
  //padding: 1em;
    margin: auto;
}

.box {
  margin: auto;
  background: white;
  color: #1f2d5e;
  display: flex;
  flex-flow: column;
  position: relative;
  border-radius: 30px;
  min-width: 250px;
  align-items: center;
  gap: 1rem;
  padding: 1rem 3rem 2rem;

  .username-input {
    display: inline-block;
    //margin: 0 !important;
    width: 95%;
  }

  .password-container {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5em;
    border-radius: 5px;
    border: solid 1px grey;

    .password-eye {
      height: 100%;
      width: 1.5em;
      margin-right: 0.5em;
    }

    input {
      border: none;
    }
  }

  .title {
    color: black;
    font-weight: bold;
    text-align: center;
  }

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  .button {
    margin-top: 1rem;
  }
}