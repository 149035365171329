html {
  background: #ebebeb url("../public/assets/img/Background.svg") no-repeat right bottom;
  height: 100%;
}

p {
  cursor: default;
}

body {
  height: 100%;
  margin: 0;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

* {
  font-family: 'M PLUS 1p', system-ui, serif !important;
}

#root {
  color: white;
  height: 100%;
  --toastify-color-progress-light: #5895CC;
  --toastify-text-color-dark: black !important;
  --toastify-text-color-error: red !important;
  --toastify-text-color-info: black !important;
  --toastify-text-color-light: black !important;
  --toastify-text-color-warning: black !important;
  --toastify-text-color-success: green !important;
  --toastify-color-light: white !important;
  --toastify-font-family: 'Source Sans Pro', sans-serif;
}
