.dialog-v2 {
  background: white;
  padding-bottom: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;

  h1 {
    color: #67c6df;
    border-radius: 3px;
    background: #1f2d5e;
    flex-grow: 1;
    width: 400px;
    top: 0;
    margin-top: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    text-align: center;
  }

  p {
    padding: 0 1em;
  }

  .modal-buttons {
    margin-left: 1em !important;
    margin-right: 1em !important;
    width: fit-content !important;
  }

  .destructive {
    background: red;
  }
}