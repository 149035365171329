@import './src/styles/shared';

.doctor-create {
  width: 100%;

  .header {
    justify-content: unset !important;
  }

  table {
    input {
      color: black;
    }

    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2em;
    width: 80%;
    @media screen and (max-width: 500px) {
      width: 100%;
      padding-left: 2.5%;
      padding-right: 0;
    }
  }
}