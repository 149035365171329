.patient-list {
  display: flex;
  flex-flow: column;
  padding: 1em !important;
  align-items: flex-start;
  gap: 1em;
  @media screen and (max-width: 500px) {
    width: 95%;
  }

  .search-box {
    display: flex;
    flex-flow: row;
    background: unset;
    padding: 1em;
    width: fit-content;
    border-radius: 2em;

    color: white;
    font-size: small;
    border: solid 1px grey;

    input {
      padding: 0;
      border: none;
      color: #1f2d5e;
    }
  }

  .patient-list-header {
    display: flex;
    flex-flow: row;
    gap: 1em;
  }

  p {
    text-align: center;
    line-height: 1.5rem;
    font-size: small;
    padding: 1.5rem 2rem;
  }

  .welcome-message {
    background: rgba(#00D0FF, 0.1);
    border: solid 1px white;
    border-radius: 3px;
  }

  .permission-message {
    background: white;
    border-radius: 3px;
    color: black;
    margin-bottom: 3rem;

    span {
      color: black;
      font-weight: bold;
    }
  }
}