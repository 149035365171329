.doctor-profile {
  display: flex;
  flex-flow: column;
  padding: 1em !important;

  .doctor-profile-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    background: #1f2d5e linear-gradient(90deg, rgba(#00eefe, 0.25), rgba(#00eefe, 0.00)) !important;
    border-radius: 1.5em;
    letter-spacing: 1.5px;
    height: 3em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin: 0 -0.5rem;
    border: solid 1px white;

    .edit-profile-btn {
      color: lightgrey;
      letter-spacing: -1px !important;
    }

    p {
      color: white;
    }
  }

  .doctor-profile-value {
    font-weight: bold;
  }

  .modify-password-btn {
    font-size: unset !important;
  }
}