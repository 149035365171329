@import 'src/styles/shared';

* {
  color: #1d1d1d;
}

.about-back-logo {
  height: 5vh;
  object-fit: contain;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
}

.about-center-logo {
  height: auto;
  top: 10vh;
  max-height: 30vh;
  width: 100%;
  margin-top: 10vh;
  align-self: center;
  object-fit: contain;
}


.about-container {
  margin-left: auto;
  margin-right: auto;

  h1 {
    color: #1d1d1d;
    font-size: 1.3rem;
    text-align: center;
    border: none !important;
  }

  .btn-container {
    display: flex;
    flex-flow: row;
    gap: 1rem;
  }

  .about-content {
    max-width: 400px;


    .logo {
      height: 33vh;
      object-fit: contain;
    }
  }
}