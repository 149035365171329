.patient-splashcreen {
  display: flex;
  flex-flow: column;
  justify-items: center;
  margin: auto;
  padding: 1em !important;

  .trigger-capture-btn {
    border-color: #00eefe;
    border-width: 2px;
    height: 4em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 2em;
    font-size: unset !important;
  }

  p {
    text-align: center;
    line-height: 1.5rem;
    font-size: small;
    padding: 1.5rem 2rem;
  }

  .welcome-message {
    color: #1d1d1d;
    letter-spacing: -0.15px;
    font-size: 16px;
    font-weight: 600;
    max-width: 724px;
  }

  .doctor-message {
    background: white;
    border-radius: 30px;
    letter-spacing: -0.15px;
    font-size: 16px;
    max-width: 724px;
    margin: 1em;
    padding: 1em 0.5em;
    color: grey;
    font-weight: lighter;
    font-style: italic;
    position: relative;
  }

  .greetings-message {
    background: #1f2d5e linear-gradient(90deg, rgba(#00eefe, 0.00), rgba(#00eefe, 0.35), rgba(#00eefe, 0.00));
    border-radius: 30px;
    letter-spacing: -0.15px;
    font-size: 16px;
    max-width: 724px;
    color: white;
    margin-bottom: 3rem;

    span {
      color: white;
      font-weight: bold;
    }
  }

  .permission-message {
    background: white;
    border-radius: 30px;
    letter-spacing: -0.15px;
    font-size: 16px;
    max-width: 724px;
    color: grey;
    font-weight: lighter;
    font-style: italic;
    margin-bottom: 3rem;

    span {
      color: black;
      font-style: initial;
      font-weight: bold;
    }
  }
}