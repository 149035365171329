.patient-details-page {
  width: 100%;
  display: flex;
  flex-flow: column;

  .patient-details-page-back {
    margin-left: 10%;
    margin-top: 2em;

    @media screen and (max-width: 500px) {
      margin: 1em;
    }
  }

  .patient-details-page-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    //gap: 2em;
  }

  .left-column {
    //display: inline-block;
    width: 500px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    span {
      color: #0d77e6;
      text-decoration-line: underline;
      font-size: small;
      right: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .right-column {
    //display: inline-block;
    width: 500px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }



  .column-header {
    color: #1f2d5e;
    margin: 0 -1em;
    padding: 1em;
    font-weight: 600;
    border: solid 1px #1f2d5e;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
}