@import 'src/styles/shared';

.header {
  //@include debug-child-div-random-background(10, 0.15); // TODO REMOVE DEBUG

  position: sticky;
  top: 0;
  width: 100%;
  height: 120px; //260px;#5895CC00
  @media screen and (max-width: 500px) {
    height: 60px;
  }

  box-shadow: 0 3px 6px #00000029;
  background: #1f2d5e no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;

  .back-logo {
    display: flex;
    height: 100%;
    max-height: 100%;
    align-items: center;
    width: calc(100%/3);
    flex-flow: row;


    img {
      object-fit: contain;
      cursor: pointer;

      height: 35%;
      margin-left: 2em;

      @media screen and (max-width: 500px) {
        margin-left: 1em;
      }
    }

    .menu-icon {
      height: 25%;
    }
  }

  .profile-logo {
    display: flex;
    flex-flow: row;
    justify-self: start !important;
    height: 45%;
    padding-right: 2em;
    margin-top: auto;
    margin-bottom: auto;
    object-fit: contain;

    @media screen and (max-width: 500px) {
      padding-right: 0.75em;
    }
  }

  .menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    visibility: hidden;
    background: rgba(#000000, 0.2);
    height: 100vh;
    width: 100vw;
    top: 0;

    .menu {
      background: rgba(#000000, 0.8);
      top: 50px;
      left: 50px;
      padding: 1em;
      border-radius: 30px;
      z-index: 999 !important;

      .menu-item {
        cursor: pointer;
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: smaller;
        text-transform: uppercase;
        padding: 0.5em;
        border-bottom: solid 1px #00eefe;
        z-index: 999 !important;
      }

      .menu-item:last-child {
        border: none;
      }
    }
  }

  .header-tab {
    display: flex;
    flex-flow: column-reverse;
    height: 100%;
    width: calc(100%/3);
    color: lightgrey;
    margin-bottom: 3px;
    font-size: 1.5em;
    line-height: 100%;
    letter-spacing: 0.08em;
    user-select: none;
    cursor: pointer;
    justify-content: center;
    text-align: center !important;

    @media screen and (max-width: 500px) {
      font-size: 1em;
    }

    &.selected {
      margin-bottom: 0;

      &.center {
        background: linear-gradient(90deg, rgba(#00eefe, 0.00), rgba(#00eefe, 0.35), rgba(#00eefe, 0.00));
      }

      &.right {
        background: linear-gradient(90deg, rgba(#00eefe, 0.00), rgba(#00eefe, 0.35));
      }
    }

    &.selected {
      color: #00eefe;
      border-bottom: solid 3px #00eefe;
    }

    &.center {
      align-items: center;
    }

    &.right {
      align-items: end;
    }
  }
}