@import 'src/styles/shared';

$standardPadding: 30px;
$small-font-size: 13px;

.list-header {
  height: 80px;

  color: lightblue;


  border-collapse: collapse;
  background-color: darkseagreen;
  border-radius: 30px;
  border: 5px solid cyan;
}

.list-body {
  background-color: darksalmon;
  border: 5px solid red;
  border-radius: 30px;

  //margin: 50px;
  padding: 200px;
}

.list {
  width: 100%;
  box-shadow: none;

  table {
    width: 100%;
    padding: 10px;
    border-collapse: collapse;

    thead {
      border: thin solid black !important;
      background: lightgrey;
    }

    thead th {
      padding: 0.5em 1em !important;
      margin: 0 !important;
    }

    th {
    }

    tbody {
      //border-collapse: separate;
      //border-spacing: 0;
    }

    tr {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:not(:last-child){
        border-bottom: solid 1px black;
      }
    }

    p {
      padding: 1em;
      margin: unset;
      text-align: unset;
    }

    td {
      vertical-align: middle !important;
      color: gray;
    }
  }
}

.table-header {
  cursor: pointer;

  text-align: left;

  padding: 16px 40px !important;
}

.columns {
  padding-left: 0 !important;
}

.list-item {
  display: flex;

  .icon {
    font-size: large;
  }
}

.empty-list-ph {
  height: 50px;
  width: 100%;
}

.list-element {
  height: 50px;
  padding: 0;
  margin: 0;

  cursor: pointer;

  text-align: left;

  font-size: 20px;
  line-height: 28px;
}

.list-element:hover {
  // tr
  & > td {
    background: #fafafa !important;
  }

  .blue-hover {
    color: #1f2d5e;
  }
}