.report-list {
  display: flex;
  flex-flow: column;
  padding: 1em !important;

  table, * {
    font-size: unset;
  }

  .column-header {
    margin-top: 2em;

    .pending {
      color: #0d77e6;
      font-size: small;
      right: 0;
      user-select: none;
    }
  }
}

.demande-releve {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 1em;
}