@import 'src/styles/shared';

.main {
  z-index: 700; // below Header anv above Canvas
  height: 100%;
  display: flex;
  flex-flow: row;
}

//h1, h2, h3, h4 {
//  font-family: 'Roboto Slab', sans-serif;
//}

h1, h2 {
  color: #5895CC;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: .75rem;
}